import { Link } from "gatsby";

import React, { useEffect } from "react";

import {
  Card,
  Container,
  Row,
  Form,
  Col,
  Button,
  InputGroup,
} from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import PhoneInput from "react-phone-input-2";
import Recaptcha from "react-google-recaptcha";

export default function CustomerSupport({ data }) {
  const recaptchaRef = React.createRef();
  const TextElemt = data.TextElemt.nodes;
  const ImageElemt = data.ImageElemt.nodes;
  const SecondImageElemt = data.SecondImageElemt.nodes;
  const HeadingResource = data.HeadingResource.nodes;
  const TextSecondElemt = data.TextSecondElemt.nodes;
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hubspot.com/forms/current.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: '20339966',
          formId: 'd6f7f11b-6f2e-4eb6-8c49-f1fa32fef4db',
          target: '#hubspotForm'
        })
      }
    });
  }, []);

  return (
    <Layout>
      <main>
        <Container fluid={true}>
          <Container>
            <section className="pt-5">
              <div className="col-xl-9 col-lg-9 col-md-12 mx-auto">
                <Row>
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <h1 className="fw-bold mb-4 fs-36 lh-46 pe-2">
                      Please submit your Customer Support Ticket
                    </h1>
                    <h5 className="fs-20 bogo-par lh-32 fw-normal pe-4">
                      Fill out the form and we’ll get back to you within 24
                      hours!
                    </h5>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 ps-xl-5">
                    <div id="hubspotForm"></div>

                  </div>
                </Row>
              </div>
            </section>
          </Container>
          <Container fluid={true} className="py-5 px-0">
            <section className="bg-light my-5 px-0 custom-h">
              <Row>
                {HeadingResource.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-12 ps-0">
                        <img
                          src={node.MultipleTitleWidgeImage}
                          className="footer-about"
                          alt="bg"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5 mt-xl-3 mt-lg-3 pe-xl-5 pe-2 ps-4 ps-xl-3">
                        <h3 className="fw-bold mb-5">
                          {node.MultipleTitleWidgetFirstTitle}
                        </h3>
                        <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 pe-5 me-3">
                          {node.MultipleTitleWidgetFirstDescription}
                        </h5>
                        <Link
                          to={node.MultipleTitleWidgetFirstCTALink}
                          className="fs-18 link-color pb-2"
                        >
                          {node.MultipleTitleWidgetFirstCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5  pe-xl-5 pe-2 ps-4 ps-xl-3">
                        <h3 className="fw-bold mb-5">
                          {node.MultipleTitleWidgetSecondTitle}
                        </h3>
                        <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 me-3">
                          {node.MultipleTitleWidgetSecondDescription}
                        </h5>
                        <Link
                          to={node.MultipleTitleWidgetSecondCTALink}
                          className="fs-18 link-color pb-2"
                        >
                          {node.MultipleTitleWidgetSecondCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>
                    </>
                  );
                })}
              </Row>
            </section>
          </Container>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
    TextElemt: allAboutJson(
      filter: { FirstTextWidgetDescription: { ne: null } }
    ) {
      nodes {
        FirstTextWidgetDescription
      }
    }
    TextSecondElemt: allAboutJson(
      filter: { SecondTextWidgetDescription: { ne: null } }
    ) {
      nodes {
        SecondTextWidgetDescription
      }
    }
    ImageElemt: allAboutJson(filter: { MainImage: { ne: null } }) {
      nodes {
        MainImage
      }
    }
    SecondImageElemt: allAboutJson(filter: { SecondImage: { ne: null } }) {
      nodes {
        SecondImage
      }
    }
    HeadingResource: allResourceJson(
      filter: { MultipleTitleWidgetFirstTitle: { ne: null } }
    ) {
      nodes {
        MultipleTitleWidgeImage
        MultipleTitleWidgetFirstCTALink
        MultipleTitleWidgetFirstDescription
        MultipleTitleWidgetFirstCTAText
        MultipleTitleWidgetFirstTitle
        MultipleTitleWidgetSecondCTALink
        MultipleTitleWidgetSecondCTAText
        MultipleTitleWidgetSecondDescription
        MultipleTitleWidgetSecondTitle
      }
    }
  }
`;
